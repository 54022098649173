import Vue from "vue"
import ToastComponent from "./src/main.vue";

const ToastConstrctor = Vue.extend(ToastComponent);
let instance;
let instances = [];
let seed = 1;
let closeFns = [];
let isClose = true;

//执行关闭
function useClose(id){
    let len = instances.length;
    let index = -1;
    let instance;
    for (let i = 0; i < len; i++) {
        if(id === instances[i].id) {
            index = i;
            instance = instances[i];
            instances.splice(i, 1);
            len--;
            break;
        }
    }
    if(instance){
        instance.$el.classList.remove('move');
        instance.visible = false;
        isClose = false;
    }

    if(len && index < len){
        for (let i = index; i < len; i++) {
            if(!instances[i]) return;
            let dom = instances[i].$el;
            dom.classList.remove('move');
            setTimeout(() => {
                dom.style.top = i * 64 + 76 + 'px';
                dom.classList.add('move');
            });
        }
    }
}

const Toast = function(options = {}, type){
    if (typeof options === 'string') {
        options = {
            message: options,
            type
        };
    }
    let id = 'toast_' + seed++;
    //关闭
    options.onClose = function(){
        closeFns.push(id);
        setTimeout(() => {
            if(closeFns.length >= 1 && isClose){
                useClose(id);
            }
        })
    }
    //动画结束执行下一个关闭
    options.onAfterLeave = function(){
        isClose = true;
        let index = closeFns.indexOf(id);
        closeFns.splice(index, 1);
        if(closeFns.length && index < closeFns.length){
            useClose(closeFns[index]);
        }
    }
    let len = instances.length;
    options.top = len * 64 + 76;

    instance = new ToastConstrctor({
        data: options
    });
    instance.id = id;
    instance.$mount();
    document.body.appendChild(instance.$el);
    instance.visible = true;
    instances.push(instance);
    return instance;
};
  
['success', 'warning', 'error', 'loading'].forEach(type => {
    Toast[type] = (options) => {
        if (typeof options === 'string') {
            return Toast({
                type,
                message: options
            });
        }
        return Toast({
            ...options,
            type
        });
    };
});
Toast.closeAll = function() {
    for (let i = instances.length - 1; i >= 0; i--) {
        instances[i].close();
    }
};
export default Toast;