<template>
    <span class="dropdown">
        <el-popover
            ref="popover"
            v-model="popoverVisible"
            :visible-arrow="false"
            :popper-class="popperClassName"
            v-bind="{
                placement: 'bottom',
                ...$attrs
            }">
            <template slot="reference"><slot></slot></template>
            <template>
                <div class="dropdown-header" v-if="$slots.header" @click="popoverVisible = false">
                    <slot name="header"></slot>
                </div>
                <div class="dropdown-menu_container">
                    <ul class="dropdown-menu" :class="menuClassName" :style="{'max-height': maxHeight}">
                        <li class="dropdown-item"
                            :class="{
                                'is-disabled': item.disabled,
                                'is-active': value !== undefined && value === item[valueKey]
                            }"
                            v-for="(item, index) in list" :key="index"
                            @click="itemChange(item)"
                        >
                            <slot name="option" v-bind="{...item, '$index': index}"><span>{{item[labelKey]}}</span></slot>
                        </li>
                    </ul>
                </div>
                <div class="dropdown-footer" v-if="$slots.footer">
                    <slot name="footer"></slot>
                </div>
            </template>
        </el-popover>
    </span>
</template>
<script>
import createComponent from '../utils/createComponent';
export default createComponent({
    name: 'Dropdown',
    props: {
        visible: Boolean,
        list: Array,
        value: [String, Number],
        valueKey:{
            type: String,
            default: 'value'
        },
        labelKey:{
            type: String,
            default: 'label'
        },
        align: {
            type: String,
            default: 'center'
        },
        maxHeight: {
            type: String,
            default: '180px'
        },
        width: String,
        top: {
            type: String,
            default: '10px'
        },
        popperClass: String
    },
    data(){
        return {
            popoverVisible: this.visible
        }
    },
    mounted() {
        this.setStyle();
    },
    watch: {
        visible(newVal){
            this.popoverVisible = newVal;
        },
        popoverVisible(newVal){
            if(newVal){
                this.setStyle();
            }
            this.$emit('update:visible', newVal);
        }
    },
    computed: {
        popperClassName(){
            return `dropdown-popper ${this.popperClass || ''}`;
        },
        menuClassName(){
            return `is-${this.align}`
        }
    },
    methods: {
        setStyle(){
            if(!this.$refs.popover) return;
            let referenceElm = this.$refs.popover.referenceElm;
            let popperElm = this.$refs.popover.$refs.popper;
            popperElm.style.width = this.width || (referenceElm.offsetWidth + 'px');
            popperElm.style.setProperty("--offsetTop", this.top);
        },
        itemChange(item){
            this.popoverVisible = false;
            this.$emit('input', item[this.valueKey]);
            this.$emit('change', item);
        }
    }
})
</script>
<style lang="scss" scoped>
    .dropdown::v-deep{
        .el-popover__reference-wrapper{
            display: inline-block;
        }
    }
    .dropdown-header,
    .dropdown-footer{
        width: 100%;
    }
    .dropdown-menu_container{
        padding: 6px 0;
    }
    .dropdown-menu{
        overflow-y: auto;
        &.is-center .dropdown-item{
            justify-content: center;
        }
        &.is-left .dropdown-item{
            justify-content: start;
        }
        &.is-right .dropdown-item{
            justify-content: end;
        }
        .dropdown-item{
            width: 100%;
            height: 30px;
            padding: 0 16px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:hover{
                background: #F2F5F6;
            }
            &.is-active{
                color: #3078FF;
            }
            &.is-disabled{
                color: #9FA3AE;
            }
        }
    }
</style>
<style lang="scss">
    .dropdown-popper{
        min-width: initial;
        padding: 0;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px -2px rgba(136,136,136,0.5);
        border-radius: 5px;
        @include text-style-default;
        
        &[x-placement^=bottom]{
            margin-top: var(--offsetTop);
        }
        &[x-placement^=top]{
            margin-bottom: var(--offsetTop);
        }
    }
</style>