import config from './config';

export default {
    // 获取毫秒级时间戳 number
	timestamp: function() {
		return Date.now()
	},
	/// 获取毫秒级时间戳 string
	timestampStr: function() {
		return `${this.timestamp()}`
	},
	// 获取秒级时间戳 number
	secondTimestamp: function() {
		return Math.floor(this.timestamp() / 1000);
	},
	// 获取秒级时间戳 string
	secondTimestampStr: function() {
		return `${this.secondTimestamp()}`
	},
	// 真实的时间戳 number
	getRealTimestamp: function(timestamp) {
		let time = timestamp ? parseInt(timestamp) : this.secondTimestamp();
        let realTime = time + this.getTimeSkew();
        return realTime;
	},
	// 真实的时间戳 string
	getRealTimestampStr: function(timestamp) {
		return `${this.getRealTimestampAsInt(timestamp)}`;
	},
    // 设置时间差
    setTimeSkew: function(localRequestTimestamp1, localRequestTimestamp2, serverResponeTimestamp){
		let serverTimestamp = parseInt(serverResponeTimestamp) || 0;
		let requestTimestamp1 = parseInt(localRequestTimestamp1) || 0;
		let requestTimestamp2 = parseInt(localRequestTimestamp2) || 0;
		let timeSkew = serverTimestamp - ((requestTimestamp2 + requestTimestamp1) / 2);
        sessionStorage.setItem(config.kTimeSkew, timeSkew);
    },
    // 获取时间差
    getTimeSkew: function(){
        let timeSkew = sessionStorage.getItem(config.kTimeSkew);
			timeSkew = timeSkew ? parseInt(timeSkew) : 0;
        return timeSkew;
    },
    // 数字转字符串
    toFixed: function(num, length = 2){
        let num1 = Number(num);
        if(isNaN(num1)){
            return num;
        }
        return num1.toFixed(length);
    },
    //获取zindex 正向
    getZIndex: function(){
        let zIndex = window.Z_INDEX || 1000;
        zIndex++;
        window.Z_INDEX = zIndex;
        return zIndex;
    },
    //格式化日期时间
    formatDate: function(date,fmt){
        if(typeof date != 'object')date = new Date(date);
        if(date == 'Invalid Date'){
            return '';
        }else{
            return (function (fmt){
                var o = {
                    "M+": this.getMonth() + 1,
                    "d+": this.getDate(),
                    "H+": this.getHours(),
                    "m+": this.getMinutes(),
                    "s+": this.getSeconds(),
                    "q+": Math.floor((this.getMonth() + 3) / 3),
                    "S": this.getMilliseconds()
                };
                fmt = fmt || "yyyy-MM-dd";
                if (/(y+)/.test(fmt))
                    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
                let HoursStyle = [],hoursText='';
                if(new RegExp(/AM|PM/).test(fmt)){
                    HoursStyle = ['AM','PM'];
                }else if(new RegExp(/am|pm/).test(fmt)){
                    HoursStyle = ['am','pm'];
                }
                if(HoursStyle.length > 0){
                    let hours = parseInt(date.getHours());
                    if(hours == 0){
                        hoursText = '12' + HoursStyle[0];
                    }else{
                        hoursText = hours >= 12 ? (hours-12 + HoursStyle[1]) : (hours + HoursStyle[0]);
                    }
                    fmt = fmt.replace(RegExp.$1, 'EE');
                }
                for (var k in o)
                    if (new RegExp("(" + k + ")").test(fmt))
                        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
                fmt = fmt.replace('EE', hoursText);
                return fmt;
            }).call(date,fmt);
        }
    },
     //完成将 toChineseNum， 可以将数字转换成中文大写的表示，处理到万级别，例如 toChineseNum(12345)，返回 一万二千三百四十五。
    toChinesNum: function(num){
        let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; //changeNum[0] = "零"
        let unit = ["", "十", "百", "千", "万"];
        num = parseInt(num);
        let getWan = (temp) => {
            let strArr = temp.toString().split("").reverse();
            let newNum = "";
            for (var i = 0; i < strArr.length; i++) {
                newNum = (i == 0 && strArr[i] == 0 ? "" : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? "" : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum;
            }
            return newNum;
        }
        let overWan = Math.floor(num / 10000);
        let noWan = num % 10000;
        if (noWan.toString().length < 4) noWan = "0" + noWan;
        return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
        
    },
    //文字高亮
    highlight(text, need, handle){
        const reg = new RegExp(need,'gi');
        return text.replace(reg,(matching)=>{
            return handle ? handle(matching) : matching;
        });
    },
    //验证手机号
    isMobile: function(mobile) {
		const MOBILE = /^1(3|4|5|6|7|8|9)\d{9}$/;
		if (!mobile) return false;
		return mobile.match(MOBILE);
	},
    copy: function(text){
        // navigator clipboard 需要https等安全上下文
        //ios终端
        let u = navigator.userAgent;
        if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) && navigator.clipboard && window.isSecureContext) {
            // navigator clipboard 向剪贴板写文本
            return navigator.clipboard.writeText(text);
        } else {
            // 创建text area
            let textArea = document.createElement("textarea");
            textArea.value = text;
            // 使text area不在viewport，同时设置不可见
            textArea.style.position = "absolute";
            textArea.style.opacity = 0;
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((resolve, reject) => {
                // 执行复制命令并移除文本框
                document.execCommand('copy') ? resolve() : reject();
                textArea.remove();
            });
        }
    },
    numFormat: function(num){
        if(!num) return;
        return num.toString().replace(/\d+/, function(n){
            return n.replace(/(\d)(?=(\d{4})+$)/g, function($1){
               return $1 + "-";
            });
       })
    },
    downloadFile(url, name){
        var a = document.createElement('a'); 
        var event = new MouseEvent('click') ;
        a.download = name;
        a.href = url;
        a.dispatchEvent(event);
    },
    selectFile(options = {}, callback){
        let promise;
        if (typeof callback !== 'function' && window.Promise) {
            promise = new window.Promise((resolve) => {
                callback = resolve;
            });
        }
        let dom = document.createElement('input'); 
        dom.type = 'file';
        dom.accept = options.accept || '';
        dom.onchange = function(){
            let file = dom.files[0];
            callback && file && callback(file);
        }
        let event = new MouseEvent('click');
        dom.dispatchEvent(event);
        if (promise) {
            return promise;
        }
    }
}

