<template>
    <transition name="popup" @before-leave="handleBeforeLeave" @after-leave="handleAfterLeave">
        <div class="popup_wrapper" :style="wrapperStyle" v-show="visible">
            <div class="popup_header">
                <div class="popup_title">
                    <slot name="title"><span v-if="title">{{title}}</span></slot>
                </div>
                <div class="popup_close" @click="close" v-if="showClose">
                    <img src="@icon/popup_close.png">
                </div>
            </div>
            <div class="popup_content">
                <div class="popup_content_scroll" ref="contentScroll">
                    <slot></slot>
                </div>
            </div>
            <div class="popup_footer" :class="footerHint ? {'top-hint': $slots.footerTopHint, 'bottom-hint': $slots.footerBottomHint} : ''" v-if="$slots.footer">
                <slot name="footer"></slot>
                <div class="footer_hint" v-if="footerHint">
                    <slot name="footerTopHint"></slot>
                    <slot name="footerBottomHint"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import createComponent from '../utils/createComponent';
export default createComponent({
    name: 'Popup',
    props: {
        visible: Boolean,
        title: String,
        width: String,
        height: String,
        showClose: {
            type: Boolean,
            default: true
        },
        destroyOnClose: Boolean,
        beforeClose: Function,
        footerHint: Boolean
    },
    data(){
        return {
            zIndex: this.$tools.getZIndex()
        }
    },
    watch:{
        visible(newVal){
            if(newVal){
                this.$operate.showMask();
                this.zIndex = this.$tools.getZIndex();
                this.$nextTick(function(){
                    this.$refs.contentScroll.scrollTop = 0;
                })
            }else{
                this.$operate.hideMask();
                this.$emit('close');
            }
        }
    },
    computed: {
        wrapperStyle(){
            let footerHeight = '0px';
            if(this.$slots.footer){
                if(this.footerHint){
                    footerHeight = this.$slots.footerTopHint ? '104px' : '88px';
                }else{
                    footerHeight = '66px';
                }
            }
            return {
                width: this.width,
                height: this.height,
                '--footerHeight': footerHeight,
                'z-index': this.zIndex
            }
        }  
    },
    methods: {
        handleBeforeLeave(){
            this.beforeClose && this.beforeClose();
        },
        handleAfterLeave() {
            if(this.destroyOnClose){
                this.$destroy(true);
                this.$el.parentNode.removeChild(this.$el);
            }
        },
        close() {
            this.$emit('update:visible', false);
        }
    }
})
</script>
<style lang="scss" scoped>
.popup-enter-active {
    @include fade-in;
}
.popup-leave-active {
    @include fade-out;
}
.popup_wrapper{
    max-height: 90vh;
    height: auto;
    width: 500px;
    background: #FFFFFF;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: calc(50% + 120px);
    transform: translate(-50%, -50%);
    .popup_header{
        height: 60px;
        width: 100%;
        background: #F7FAFC;
        border-radius: 10px 10px 0px 0px;
        padding: 0 22px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        position: relative;
        .popup_title{
            font-family: PingFangSC-Medium;
            font-size: 20px;
            color: #293647;
            font-weight: 500;
        }
        .popup_close{
            width: 20px;
            height: 20px;
            position: absolute;
            top: calc(50% - 10px);
            right: 17px;
            cursor: pointer;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .popup_content{
        height: calc(100% - 60px - var(--footerHeight));
        padding: 22px 0;
        box-sizing: border-box;
        .popup_content_scroll{
            --content: calc(90vh - 60px - 44px - var(--footerHeight));
            max-height: var(--content);
            height: 100%;
            padding: 0 22px;
            box-sizing: border-box;
            overflow-y: auto;
        }
    }
    .popup_footer{
        height: var(--footerHeight);
        width: 100%;
        display: flex;
        justify-content: center;
        &.top-hint{
            padding-top: 38px;
            box-sizing: border-box;
            position: relative;
            .footer_hint{
                top: 0;
            }
        }
        &.bottom-hint{
            position: relative;
            .footer_hint{
                top: 44px;
            }
        }
        .footer_hint{
            width: 100%;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
        }
    }
}
</style>