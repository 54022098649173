<template>
    <div class="page_head">
        <slot name="operation"></slot>
        <div class="tabbar">
            <ul>
                <li v-for="(item,index) in tabs" :key="index" :class="{
                    'active': getActive(item, index)
                }" @click="handleClick(item)">
                    <slot name="tabItem" v-bind="{...item, '$index': index}">{{item[labelKey]}}</slot>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import createComponent from '../utils/createComponent';
export default createComponent({
    name: 'PageHead',
    props: {
        value: [String, Number],
        tabs: Array,
        valueKey:{
            type: String,
            default: 'value'
        },
        labelKey:{
            type: String,
            default: 'label'
        },
        active: Function
    },
    methods: {
        getActive(item, index){
            if(this.active){
                return this.active(item, index);
            }else{
                return this.value === item[this.valueKey];
            }
        },
        handleClick(item){
            this.$emit('input', item[this.valueKey]);
            this.$emit('tab-click', item);
        }
    }
})
</script>
<style lang="scss">
    div:has(> .page_head){
        --page-head-height: 66px;
    }
</style>
<style lang="scss" scoped>
    .page_head{
        width: 100%;
        height: var(--page-head-height);
        background: #FFFFFF;
        box-shadow: 0px 1px 20px -4px rgba(136,136,136,0.25);
        display: flex;
        .tabbar{
            height: 100%;
            padding-left: 22px;
            display: flex;
            align-items: center;
            border-left: 1px solid #E9E9E9;
            box-sizing: border-box;
            ul{
                height: 100%;
                display: flex;
                li{
                    flex: none;
                    height: 100%;
                    margin-right: 76px;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    cursor: pointer;
                    &:last-of-type{
                        margin-right: 0;
                    }
                    &.active{
                        font-family: PingFangSC-Medium;
                        color: #3078FF;
                        font-weight: 500;
                        position: relative;
                        &::after{
                            content: '';
                            width: 100%;
                            height: 3px;
                            transform: scaleY(-1);
                            background: #3078FF;
                            position: absolute;
                            left: 0;
                            bottom: 8px;
                        }
                    }
                }
            } 
        }
    }
</style>